import React, { useState } from 'react';
import { storage, ref, uploadBytes, getDownloadURL } from '../firebase';

const FileUpload = ({ onUploadSuccess }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (file) {
      const storageRef = ref(storage, file.name);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        console.log('File uploaded successfully!');
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File URL:', downloadURL);

        // Pass the download URL to the parent component
        onUploadSuccess(downloadURL);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload File</button>
    </div>
  );
};

export default FileUpload;
