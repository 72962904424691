import React, { Fragment, useState, useEffect } from "react";
import { doc, getDoc, writeBatch, collection } from "firebase/firestore";
// import Hero from "../components/Hero";
// import Lobby from "../components/Lobby";
// import Content from "../components/Content";
import { db } from '../firebase';
import { useAuth0 } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import {DatePicker} from 'reactstrap-date-picker'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
// import { Progress } from "reactstrap";
import logo from "../assets/tsg-logo-tra.png";
import backpokemon from "../assets/card-back.png";
import { useHistory } from 'react-router-dom';

const Home = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [gotError, setGotError] = useState(false);
  const [newInfoForm, setnewInfoForm] = useState({});
  const [cardData, setcardData] = useState({});
  const [dateGraded, setDateGraded] = useState(new Date().toISOString()); // Set initial date if needed
  const [modalOpen, setModalOpen] = useState(false);
  const tempstuff = {
    serialNumber: "",
};
  const handleDateChange = (value, _) => {
    // The 'value' parameter contains the selected date
    setDateGraded(value);
  };
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

    const setserialNumberValie = newInfoForm.serialNumber === "" ? true : false;


    // ONLY RUN THIS IMPORT- DANGEROUS!!!!
    // useEffect(() => {
    //   // Initialize Firebase
    //   const collectionName = 'gradedCards';
    //   const jsonData = require('../tsg.json');
  
    //   const batch = writeBatch(db);
  
    //   (async () => {
    //     for (let index = 0; index < jsonData.length; index++) {
    //       const obj = jsonData[index];
    //       obj.serialNumber = index + 1;
    //       obj.documentId = (index + 1).toString();
  
    //       const docRef = doc(collection(db, collectionName), obj.documentId);
    //       batch.set(docRef, obj);
    //     }
  
    //     try {
    //       await batch.commit();
    //       console.log('Batch committed successfully.');
    //     } catch (error) {
    //       console.error('Error committing batch:', error);
    //     }
    //   })();
    // }, []);


  const disabledButton = (setserialNumberValie);

  const lookUpCard = async () => {
    try {
      const serialNumber = newInfoForm.serialNumber.toString();  
      const cardRef = doc(db, 'gradedCards', serialNumber);
  
      const cardDoc = await getDoc(cardRef);
  
      if (cardDoc.exists()) {
        setGotError(false)
        toggleModal();
        const cardData = cardDoc.data();
        setcardData(cardData);
        // Handle the retrieved card data
        console.log('Card data:', cardData);
      } else {
        // Handle if the document doesn't exist
        setGotError(true);
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error looking up card:', error);
      setGotError(true);
      // Handle error state or other actions if needed
    }
  };
  const clearForm = () => {
  setnewInfoForm(tempstuff);
  }
  const handleChange = (e) => {
      const name = e.target.name;
      
      const value = e.target.value;
      // console.log('e name', name);
      // console.log('e value', value);
      setnewInfoForm(values => ({...values, [name]: value}));
  }

  useEffect(() => {
    // Use a setTimeout to hide the loading screen after 10 seconds (10000 milliseconds)
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 4000);

    // Clear the timer if the component unmounts (cleanup)
    // return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // const innerWidth = window.innerWidth;
    // if(innerWidth <= 750 ) {
    //   setmobileView(true);
    // }
    // if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   // true for mobile devicethis.mobileView = true;
    //   setmobileView(true);
    // };
    setnewInfoForm(tempstuff);
    return () => {};
  }, []);
  
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const goSubnimt = () => {
    history.push('/submit');
  };

  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Saving...</h1>
        {/* <Progress
          value={100}
          color="warning"
          className="thinner-progress-bar"
          style={{ width: '100px', ...progressBarAnimationStyle }}
        /> */}
      </div>
    ) : (
      <div className="main-content">
        <div className="form-head">
          <img className="mb-3 logo-tcg" src={logo} width="120" />
          <h3 id="header" data-text="TSG Database Form">Card Lookup</h3>
        </div>
        {!modalOpen &&
           <>
         
           <div>
           <form >
             <div className="form1">
               <div className="ui-inputgroup fully">
                 <label>Card Number:</label>
                 <input
                     type="text" 
                     name="serialNumber"
                     id="serialNumber"
                     required
                     placeholder="(ex: 12345)"
                     value={newInfoForm.serialNumber || ""}
                     onChange={(e) => {handleChange(e);}}
                 />
               </div>  
             </div>
             
             
             
             <div className="btncontainer">
                 {/* <Button
                   id="qsCancelBtn"
                   color="primary"
                   block
                     onClick={() => clearForm()}
                     >
                         Clear
                 </Button> */}
                 <Button
                   id="qsLoginBtn"
                   color="primary"
                   disabled={disabledButton || modalOpen}
                   block
                     onClick={() => lookUpCard()}
                     >
                         Search
                 </Button>
               </div>     
             </form>
             </div>
             <hr className="rule" />
           </>
        }
      
        <div>

          
          {modalOpen && 
            <Table bordered responsive>
            <thead>
              <tr>
                <th colSpan="2" className={(cardData.category === "Pokémon " || cardData.category === "Pokémon") ? 'pokemon-header' : (cardData.category === "Baseball " || cardData.category === "Baseball") ? 'baseball-header' : (cardData.category === "Magic" || cardData.category === "Magic ") ? 'mtg-header' : cardData.category === "lorcana" ? 'lorcana-header' :  (cardData.category === "Yugioh" || cardData.category === "Yugioh ") ? 'yugio-header' : ''}>
                  TSG Grade Details
                  <div className="smallhead">#{cardData.serialNumber}</div>
                  <div className="catgo">{cardData.category === "magic" ? 'MTG' : cardData.category === "lorcana" ? 'Disney Lorcana' : cardData.category}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td colSpan="2" className="headerbody">
                  <div className="logo-tcg2">
                    <div className="caprio">
                      <span className="modal-value">{cardData.cardType}</span>
                    </div>
                    <img className="mb-3 logo-tcg2" src={backpokemon} alt="Card Not Pictured" />
                    <span className="labelil">Actual Card Not Pictured</span>
                  </div>
                </td>
              </tr> */}
              <tr>
                <th scope="row">Name:</th>
                <td>{cardData.cardName}</td>
              </tr>
              <tr>
                <th scope="row">Set:</th>
                <td>{`${cardData.setName} - [${cardData.year}]`}</td>
              </tr>
              <tr>
                <th scope="row">Card Number:</th>
                <td>{cardData.cardNumber}</td>
              </tr>
              <tr>
                <th scope="row">Grade:</th>
                <td>{cardData.grade}</td>
              </tr>
              <tr>
                <th scope="row">Language:</th>
                <td>{cardData.language}</td>
              </tr>
              {/* <tr>
                <th scope="row">Card Type:</th>
                <td>{cardData.cardType}</td>
              </tr> */}
              {/* Add more rows for other card details */}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">
                  <Button
                    id="qsLStartBtn"
                    color="primary"
                    block
                    onClick={() => { toggleModal(); clearForm(); }}
                  >
                    Start Over
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
          }
          {gotError &&
            <div className="notfound">
              Serial number can not be found. <br/> If missing, please <span style={{cursor: "pointer", color: "lightblue", textDecoration: "underline"}} onClick={() => { goSubnimt(); }}>submit your graded card for review TSG.</span>
            </div>
          }
        </div>
       {/* <Hero /> */}
      </div>
    )}
   
  </Fragment>
)};

export default Home;
