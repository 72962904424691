import React from 'react';

const GradingResult = ({ data }) => {
  if (!data) {
    return null;
  }

  const { corners, edges, final, surface, condition } = data.records[0].grades;
  const subCorners = data.records[0].corners;
  const subEdges = data.records[0].edges;
  const cardImage = data.records[0]._full_url_card;

  return (
    <div style={{ display: 'flex', marginTop: '40px' }}>
      <div className="grades" style={{ marginRight: '20px', flex: '1' }}>
        <div>
          <div className='grade-holder'>
            <label>Overall Grade:</label>
            <h2>{final}</h2>
          </div>
          <div className='grade-holder'>
            <label>Condition:</label>
            <h2>{condition}</h2>
          </div>
          <div className='grade-holder'>
            <label>Corners Grade:</label>
            <h2>{corners}</h2>
            {Array.isArray(subCorners) ? (
              subCorners.map((corner, index) => (
                <div key={index} className='corner-info'>
                  <p>Name: {corner.name}</p>
                  <p>Grade: {corner.grade}</p>
                </div>
              ))
            ) : null}
          </div>
          <div className='grade-holder'>
            <label>Edges Grade:</label>
            <h2>{edges}</h2>
            {Array.isArray(subEdges) ? (
              subEdges.map((edge, index) => (
                <div key={index} className='edge-info'>
                  <p>Name: {edge.name}</p>
                  <p>Grade: {edge.grade}</p>
                </div>
              ))
            ) : null}
          </div>
          <div className='grade-holder'>
            <label>Surface Grade:</label>
            <h2>{surface}</h2>
          </div>
        </div>
      </div>

      <div>
        <img src={cardImage} alt="Card" style={{ width: '300px', height: 'auto' }} />
      </div>
    </div>
  );
};

export default GradingResult;
