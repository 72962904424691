import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBkBpDTkITgy2TQJh3y5JgdDg9VMQTqSps",
  authDomain: "database-f5d71.firebaseapp.com",
  databaseURL: "https://database-f5d71-default-rtdb.firebaseio.com",
  projectId: "database-f5d71",
  storageBucket: "database-f5d71.appspot.com",
  messagingSenderId: "372796603005",
  appId: "1:372796603005:web:e05866a68f929b7a428f14",
  measurementId: "G-JXHYJ7CX96"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app); // For Firebase Storage

export { db, storage, ref, uploadBytes, getDownloadURL };