import React, { useState } from 'react';
import FileUpload from './FileUpload';
import GradingResult from '../views/GradingResults';

const LabelGenComponent = () => {
  const [result, setResult] = useState(null);
  const [abilityIdOrName, setAbilityIdOrName] = useState('');
  const [abilityData, setAbilityData] = useState(null);
  const handleApiCall = async (imageUrl) => {
    console.log('call api with ', imageUrl);
    const apiUrl = 'https://api.ximilar.com/card-grader/v2/grade';
    const apiToken = process.env.REACT_APP_API_TOKEN;

    const requestBody = {
      records: [{ _url: "https://product-images.tcgplayer.com/fit-in/874x874/454233.jpg" }],
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Token ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      console.log('data ', data);
      setResult(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCollectiblesApiCall = async (imageUrl) => {
    console.log('call collectibles api with ', imageUrl);
    const apiUrl = 'https://api.ximilar.com/collectibles/v2/tcg_id';
    const apiToken = process.env.REACT_APP_API_TOKEN;
    
    const requestBody = {
      records: [{ _url: imageUrl }],
      lang: false,
      slab_id: false,
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Token ${apiToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };
  
    try {
        const response = await fetch(apiUrl, requestOptions);
        
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error:', response.status, errorData);
          // Handle errors as needed
          return;
        }
      
        const data = await response.json();
        console.log('data ', data);
        // Handle the result as needed
      } catch (error) {
        console.error('Error:', error);
        // Handle errors as needed
      }
  };
  
  const handleGetAbility = async () => {
    // Check if abilityIdOrName is not empty
    if (!abilityIdOrName.trim()) {
      console.error('Ability ID or Name is required');
      return;
    }

    const apiUrl = `https://pokeapi.co/api/v2/pokemon/${abilityIdOrName.toLowerCase()}/`;
    // const apiUrl = `https://cors-anywhere.herokuapp.com/https://pokeapi.co/api/v2/ability/${abilityIdOrName.toLowerCase()}/`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setAbilityData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSearchCard = async () => {
    // Check if abilityIdOrName is not empty
    if (!abilityIdOrName.trim()) {
      console.error('Ability ID or Name is required');
      return;
    }
  
    const apiUrl = `https://api.pokemontcg.io/v2/cards/${abilityIdOrName.toLowerCase()}/`;
    // const apiUrl = `https://cors-anywhere.herokuapp.com/https://pokeapi.co/api/v2/ability/${abilityIdOrName.toLowerCase()}/`;
  
    const tsgToken = process.env.REACT_APP_TSG_TOKEN;
  
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ${tsgToken}`,
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setAbilityData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return (
    <div>
      <FileUpload onUploadSuccess={handleApiCall} />
      {/* <FileUpload onUploadSuccess={handleApiCall} />  for the card grading API */}
      {/* <button onClick={handleApiCall}>Make API Call</button> */}
      {result && (
        <div>
          {/* <h2>API Response:</h2> */}
            <GradingResult data={result} />
          {/* <pre style={{ color: 'white' }}>{JSON.stringify(result, null, 2)}</pre> */}
        </div>
      )}
      <div>
        <label htmlFor="abilityInput">Enter Ability ID or Name: </label>
        <input
          type="text"
          id="abilityInput"
          value={abilityIdOrName}
          onChange={(e) => setAbilityIdOrName(e.target.value)}
        />
      </div>
      <button onClick={handleSearchCard}>Get Ability Information</button>

      {abilityData && (
        <div>
          <h3>Ability Information:</h3>
          <pre style={{ color: 'white' }}>{JSON.stringify(abilityData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default LabelGenComponent;
