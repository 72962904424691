import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getDocs, collection, doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';
// import './CardModal.css';

const CardModal = ({ isOpen, onRequestClose }) => {
  const [cards, setCards] = useState([]);

  const fetchData = async () => {
    const collectionName = 'gradedCardsSubmissions';
    const querySnapshot = await getDocs(collection(db, collectionName));

    const cardsData = [];
    querySnapshot.forEach((doc) => {
      cardsData.push({ ...doc.data(), documentId: doc.id });
    });

    setCards(cardsData);
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleApprove = async (card) => {
    console.log('card', card);
    // Implement the logic for approving the card
    // Check if the documentId exists in the gradedCards collection
    const gradedCardsCollection = 'gradedCards';
    const submissionsCollection = 'gradedCardsSubmissions';
  
    // Check if the documentId exists in the gradedCards collection
    const gradedCardDocRef = doc(db, gradedCardsCollection, card.documentId);
    const gradedCardDocSnapshot = await getDoc(gradedCardDocRef);
    console.log('gradedCardDocSnapshot ', !gradedCardDocSnapshot.exists());
    if (!gradedCardDocSnapshot.exists()) {
      // Document does not exist in gradedCards collection
      // Add to gradedCards collection
      await setDoc(gradedCardDocRef, { ...card });
  
      // Remove from gradedCardSubmissions collection
      const submissionDocRef = doc(db, submissionsCollection, card.documentId);
      await deleteDoc(submissionDocRef);
  
      // Fetch updated data
      fetchData();
  
      toast.success('SUCCESS: Card approved and moved to main database.', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // Document already exists in gradedCards collection
      // Prompt the user for confirmation to overwrite
      const userConfirmed = window.confirm('#'+card.documentId + ' card already exists. Do you want to overwrite it?');
      if (userConfirmed) {
        // Overwrite the existing document
        await setDoc(gradedCardDocRef, { ...card });
  
        // Remove from gradedCardSubmissions collection
        const submissionDocRef = doc(db, submissionsCollection, card.documentId);
        await deleteDoc(submissionDocRef);
  
        // Fetch updated data
        fetchData();
  
        toast.success('SUCCESS: Card approved and moved to main database.', {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // User cancelled the overwrite operation
        console.log('Overwrite cancelled');
      }
    }
  };
  
  

  const handleDelete = async (card) => {
    // Implement the logic for deleting the card
    const submissionsCollection = 'gradedCardsSubmissions';

    // Remove from gradedCardSubmissions collection
    const submissionDocRef = doc(db, submissionsCollection, card.documentId);
    await deleteDoc(submissionDocRef);

    // Fetch updated data
    fetchData();
    toast.success('SUCCESS: User submission was rejected.', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Graded Cards Modal"
    >
      <div className="modal-header">
        <h2>Graded Cards Submissions</h2>
        <button className="close-button" onClick={onRequestClose}>
          Close
        </button>
      </div>
      {cards.map((card, index) => (
        <div key={card.documentId} className="result-table-container">
          <Table bordered responsive>
            <thead>
              <tr>
                <th colSpan="2" className={(card.category === "Pokémon " || card.category === "Pokémon") ? 'pokemon-header' : (card.category === "Baseball " || card.category === "Baseball") ? 'baseball-header' : (card.category === "Magic" || card.category === "Magic ") ? 'mtg-header' : card.category === "lorcana" ? 'lorcana-header' :  (card.category === "Yugioh" || card.category === "Yugioh ") ? 'yugio-header' : ''}>
                  TSG Grade Details
                  <div className="smallhead">#{card.serialNumber}</div>
                  <div className="catgo">{card.category === "magic" ? 'MTG' : card.category === "lorcana" ? 'Disney Lorcana' : card.category}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Name:</th>
                <td>{card.cardName}</td>
              </tr>
              <tr>
                <th scope="row">Set:</th>
                <td>{`${card.setName} - [${card.year}]`}</td>
              </tr>
              <tr>
                <th scope="row">Card Number:</th>
                <td>{card.cardNumber}</td>
              </tr>
              <tr>
                <th scope="row">Grade:</th>
                <td>{card.grade}</td>
              </tr>
              <tr>
                <th scope="row">Language:</th>
                <td>{card.language}</td>
              </tr>
              {/* Add more rows for other card details */}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">
                  <div className="button-container">
                    <div className='buttono' onClick={() => handleApprove(card)}>
                      Approve
                    </div>
                    <div className='buttono del' onClick={() => handleDelete(card)}>
                      Reject
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      ))}
      {cards.length === 0 &&
        <div className="notfound" style={{marginTop: "40px"}}>
            Currently there are no user submissions available.
        </div>
        }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </Modal>
  );
};

export default CardModal;
