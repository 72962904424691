import React, { Fragment, useState, useEffect } from "react";
import { collection, getDoc, setDoc, doc } from "firebase/firestore";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { db } from '../firebase';
import { useHistory } from 'react-router-dom';
import YearPicker from '../utils/yearpicker.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useAuth0 } from "@auth0/auth0-react";
import {DatePicker} from 'reactstrap-date-picker'
// import { Progress } from "reactstrap";
import {
  Button
} from "reactstrap";
import logo from "../assets/tsg-logo-tra.png";

const Suggestion = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
//   const [newInfoForm, setnewInfoForm] = useState({
//     year: null, // Set the initial value to null or the default year
//     // ... other form fields
//   });
  const [newInfoForm, setnewInfoForm] = useState({});
  const [dateGraded, setDateGraded] = useState(new Date().toISOString()); // Set initial date if needed
  const tempstuff = {
    cardName: '',
    cardNumber: "",
    category: '',
    grade: null,
    language: 'English',
    serialNumber: "",
    setName: '',
    year: null,
    // dateGraded: ''
};


const handleYearChange = (date) => {
    if (date) {
      // Extract the year part from the date object manually
      const selectedYear = date.getFullYear().toString();
      setnewInfoForm({ ...newInfoForm, year: selectedYear });
    }
  };
   

  const goHome = () => {
    // The 'value' parameter contains the selected date
    history.push('/');
  };
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

    const cardNameValid = newInfoForm.cardName === "" ? true : false;
    const setNameValie = newInfoForm.setName === "" ? true : false;
    const setyearValie = newInfoForm.year === "" ? true : false;
    const setcardNumberValie = newInfoForm.cardNumber === "" ? true : false;
    const setcardTypeValie = newInfoForm.language === "" ? true : false;
    const setcategoryValie = newInfoForm.category === "" ? true : false;
    const setserialNumberValie = newInfoForm.serialNumber === "" ? true : false;
    const setgradeValie = newInfoForm.grade === 0 ? true : false;

    const disabledButton = (cardNameValid || setcardTypeValie || setcategoryValie || setserialNumberValie || setNameValie || setyearValie || setgradeValie || setcardNumberValie);
  
    const saveCard = async () => {
        setIsLoading(true);
        console.log('Saving ', newInfoForm);
      
        const generatedDocumentId = newInfoForm.serialNumber;
        const customDocRef = doc(db, 'gradedCardsSubmissions', generatedDocumentId);
      
        try {
          // Check if the document already exists
          const existingDoc = await getDoc(customDocRef);
      
          if (existingDoc.exists()) {
            toast.error('ERROR: Card under this serial number already exists in the TSG Card databse!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setIsLoading(false);
          } else {
                // Document does not exist, proceed to save
                const formedData = newInfoForm;
                    
                // Save the document
                await setDoc(customDocRef, { ...formedData });

                // Handle success
                console.log('Document added successfully');
                clearForm();
                setIsLoading(false);
                toast.success('SUCCESS: Card Submitted! Your submission will be reviewed shortly.', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
          }
        } catch (error) {
          // Handle error
          console.error('Error adding document:', error);
          // Handle error state or other actions if needed
        }
      };
  const clearForm = () => {
  setnewInfoForm(tempstuff);
  }
  const handleChange = (e) => {
      const name = e.target.name;
      
      const value = e.target.value;
      // console.log('e name', name);
      // console.log('e value', value);
      setnewInfoForm(values => ({...values, [name]: value}));
  }

  useEffect(() => {
    // Use a setTimeout to hide the loading screen after 10 seconds (10000 milliseconds)
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 4000);

    // Clear the timer if the component unmounts (cleanup)
    // return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // const innerWidth = window.innerWidth;
    // if(innerWidth <= 750 ) {
    //   setmobileView(true);
    // }
    // if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   // true for mobile devicethis.mobileView = true;
    //   setmobileView(true);
    // };
    setnewInfoForm(tempstuff);
    return () => {};
  }, []);
  
  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Submitting Card...</h1>
        {/* <Progress
          value={100}
          color="warning"
          className="thinner-progress-bar"
          style={{ width: '100px', ...progressBarAnimationStyle }}
        /> */}
      </div>
    ) : (
      <div className="main-content">
       <div className="form-head">
        <img className="mb-3 logo-tcg" src={logo} width="120" />
        <h3 id="header" data-text="TSG Database Form">Submit Card Details</h3>
        <div  onClick={() => goHome()} style={{textDecoration: "underline"}}>Back to Search</div>
       </div>
        <div>
        <form >
          <div className="form1">
        
                            <div className="ui-inputgroup fully">
                                <label>Card Name:</label>
                                <input
                                    type="text" 
                                    name="cardName"
                                    id="cardName"
                                    required
                                    value={newInfoForm.cardName || ""}
                                    onChange={(e) => {handleChange(e);}}
                                />
                            </div>

                            <div className="ui-inputgroup fully">
                                <label>Set</label>
                                <input
                                    type="text" 
                                    name="setName"
                                    id="setName"
                                    required
                                    value={newInfoForm.setName || ""}
                                    onChange={(e) => {handleChange(e);}}
                                />
                            </div>
                            <div className="steez ui-inputgroup half-size">
                              <label>Category:</label>
                              <select
                                name="category" 
                                value={newInfoForm.category}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value={null}>Select</option>
                                <option value={"Lorcana"}>Disney Lorcana</option>
                                <option value={"Magic"}>Magic The Gathering</option>
                                <option value={"Pokémon"}>Pokemon</option>
                                <option value={"Baseball"}>Baseball</option>
                                <option value={"Basketball"}>Basketball</option>
                                <option value={"Hockey"}>Hockey</option>
                                <option value={"Football"}>Football</option>
                                <option value={"Yugioh"}>Yugioh</option>
                                <option value={"Other"}>Other</option>
                              </select>
                            </div>
                            <div className="ui-inputgroup half-size">
                                <label>Year:</label>
                                <input
                                    type="number" 
                                    name="year"
                                    id="year"
                                    required
                                    placeholder="2024"
                                    value={newInfoForm.year || ""}
                                    onChange={(e) => {handleChange(e);}}
                                />
                            </div>
                            <div className="ui-inputgroup half-size dony">
                                <label>Grade:</label>
                                <input
                                  type="number"
                                  id="grade"
                                  name="grade"
                                  value={newInfoForm.grade}
                                  min="0"
                                  max="10"
                                  onChange={(e) => { handleChange(e); }}
                                />
                            </div>
                            
                            <div className="ui-inputgroup half-size dony">
                                <label>Card #:</label>
                                <input type="text" id="cardNumber" name="cardNumber" value={newInfoForm.cardNumber} onChange={(e) => {handleChange(e);}}>

                                </input>
                            </div>
                            
                            <div className="ui-inputgroup half-size dony">
                                <label>Serial #:</label>
                                <input type="test" id="serialNumber" name="serialNumber" value={newInfoForm.serialNumber} onChange={(e) => {handleChange(e);}}>

                                </input>
                            </div>
                           
                            <div className="steez ui-inputgroup half-size">
                              <label>Language:</label>
                              <select
                                name="language" 
                                value={newInfoForm.language}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value={null}>Select</option>
                                <option value={"Chinese"}>Chinese</option>
                                <option value={"English"}>English</option>
                                <option value={"French"}>French</option>
                                <option value={"German"}>German</option>
                                <option value={"Indonesian"}>Indonesian</option>
                                <option value={"Italian"}>Italian</option>
                                <option value={"Japanese"}>Japanese</option>
                                <option value={"Korean"}>Korean</option>
                                <option value={"Spanish"}>Spanish</option>
                              </select>
                            </div>
                            
                        </div>
                        <hr className="rule" />
                        <div className="btncontainer">
                          
                          <Button
                            id="qsCancelBtn"
                            color="primary"
                            block
                              onClick={() => clearForm()}
                              >
                                  Clear
                          </Button>
                          <Button
                            id="qsLoginBtn"
                            color="primary"
                            disabled={disabledButton}
                            block
                              onClick={() => saveCard()}
                              >
                                  Submit
                          </Button>
                        </div>
                        </form>
        </div>
       {/* <Hero /> */}
      </div>
    )}
     <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
  </Fragment>
)};

export default Suggestion;
  
